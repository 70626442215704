import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import "./ChangePassword.css";

export default function ChangePassword() {
  const [fields, handleFieldChange] = useFormFields({
    password: "",
    oldPassword: "",
    confirmPassword: "",
  });
  const nav = useNavigate();
  const [isChanging, setIsChanging] = useState(false);

  function validateForm() {
    return (
      fields.oldPassword.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsChanging(true);

    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(currentUser, fields.oldPassword, fields.password);

      nav("/settings");
    } catch (e) {
      onError(e);
      setIsChanging(false);
    }
  }

  return (
    <div className="ChangePassword">
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="oldPassword">
          <Form.Label>Old Password</Form.Label>
          <Form.Control type="password" value={fields.oldPassword} onChange={handleFieldChange} />
        </Form.Group>
        <hr />
        <Form.Group size="lg" controlId="password">
          <Form.Label>New Password</Form.Label>
          <Form.Control type="password" value={fields.password} onChange={handleFieldChange} />
        </Form.Group>
        <Form.Group size="lg" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.confirmPassword}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <LoaderButton
          block="true"
          size="lg"
          type="submit"
          isLoading={isChanging}
          disabled={!validateForm()}
        >
          Change Password
        </LoaderButton>
      </Form>
    </div>
  );
}
