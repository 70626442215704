import React, { useRef, useState, useMemo, useEffect, useCallback } from "react";
import { API, Storage } from "aws-amplify";
import { Predictions } from "@aws-amplify/predictions";
import Form from "react-bootstrap/Form";
import { useParams, useNavigate } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../lib/errorLib";
import config from "../config";
import "./Note.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import parse from "html-react-parser";
import useKeypress from "react-use-keypress";
import { useAppContext } from "../lib/contextLib";
import "./slider.css";
import debounce from "lodash.debounce";

export default function Note() {
  const { id } = useParams();
  const nav = useNavigate();
  const [note, setNote] = useState(null);
  const [wysiwygEditorHeight, setWysiwygEditorHeight] = useState(24);
  const [content, setContent] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [originalCategory, setOriginalCategory] = useState("");
  const [originalContent, setOriginalContent] = useState("");
  const [originalFrom, setOriginalFrom] = useState("");
  const [title, setTitle] = useState("");
  const [from, setFrom] = useState("");
  const [category, setCategory] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeReadingParagraphNumber, setActiveReadingParagraphNumber] = useState(-1);
  const [activeReadingSentenceNumber, setActiveReadingSentenceNumber] = useState(-1);
  const [activeReadingSentenceHTML, setActiveReadingSentenceHTML] = useState("");
  const [audio] = useState(new Audio());
  const [readingMode, setReadingMode] = useState(false);
  const [currentParagraphSentences, setCurrentParagraphSentences] = useState([]);
  const [currentParagraphHtmlSentences, setCurrentParagraphHtmlSentences] = useState([]);
  const [currentReadingDiv, setCurrentReadingDiv] = useState(null);
  const [showPlayButton, setShowPlayButton] = useState(false);
  const focusRef = useRef(null);
  const [swipeStartInfo, setSwipeStartInfo] = useState(null);
  const { speakText, setSpeakText } = useAppContext();
  const { onSaveStayOnNote, setOnSaveStayOnNote } = useAppContext();
  const { autoAdvanceWhenSpeakingText, setAutoAdvanceWhenSpeakingText } = useAppContext();
  const [lastSwipeEvent, setLastSwipeEvent] = useState(0);
  const [readAnotherFromFirstParagraph, setReadAnotherFromFirstParagraph] = useState(false);

  useEffect(() => {
    if (focusRef.current) {
      focusRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeReadingParagraphNumber]);

  useEffect(() => {
    function loadNote() {
      return API.get("notes", `/notes/${id}`);
    }

    async function onLoad() {
      try {
        if (id !== "new") {
          const note = await loadNote();
          const { content: loadContent, title, category, articleFrom } = note;

          document.title = "Notes - " + title;

          setTitle(title);
          setCategory(category);
          setContent(loadContent);
          setOriginalContent(loadContent);
          setFrom(articleFrom);
          setOriginalTitle(title);
          setOriginalCategory(category);
          setOriginalFrom(articleFrom);
          setNote(note);
        }
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
  }, [id]);

  const KEY_CODES = {
    SPACE: " ",
    ARROW_LEFT: "ArrowLeft",
    ARROW_RIGHT: "ArrowRight",
  };

  useKeypress([KEY_CODES.SPACE, KEY_CODES.ARROW_LEFT, KEY_CODES.ARROW_RIGHT], ({ key, preventDefault }) => {
    if (!readingMode || !audio.src) {
      return;
    }

    const isFirstSentence = activeReadingSentenceNumber === 0;
    const isLastSentence = activeReadingSentenceNumber + 1 === currentParagraphSentences.length;

    switch (key) {
      case KEY_CODES.ARROW_LEFT:
        if (isFirstSentence) {
          if (currentReadingDiv.previousSibling) {
            currentReadingDiv.previousSibling.click();
          } else {
            audio.currentTime = 0;
          }
        } else {
          speakIt(currentParagraphSentences, currentParagraphHtmlSentences, activeReadingSentenceNumber - 1, currentReadingDiv);
        }
        break;

      case KEY_CODES.ARROW_RIGHT:
        if (!isLastSentence) {
          speakIt(currentParagraphSentences, currentParagraphHtmlSentences, activeReadingSentenceNumber + 1, currentReadingDiv);
        } else if (currentReadingDiv.nextSibling) {
          currentReadingDiv.nextSibling.click();
        }
        break;

      case KEY_CODES.SPACE:
        playPause();
        break;
    }

    preventDefault();
  });

  function playPause() {
    if (audio.src === "") {
      return;
    }

    if (audio.paused) {
      audio.currentTime = 0;
      audio.play();
    } else {
      audio.pause();
    }
  }

  function validateForm() {
    return content.length > 0 && title.length > 0 && category.length > 0;
  }

  function createNote(note) {
    return API.post("notes", "/notes", {
      body: note,
    });
  }

  function saveNote(note) {
    return API.put("notes", `/notes/${id}`, {
      body: note,
    });
  }

  function flushDebounce() {
    console.log("flushing debounce");
    debouncedCKEditorChangeHandler.flush();
    console.log("flushed debounce");
  }

  async function handleSubmit(event) {
    event.preventDefault();

    flushDebounce();

    setIsLoading(true);

    try {
      if (id === "new") {
        const newNote = await createNote({ content, title, category, from });
        if (onSaveStayOnNote) {
          nav(`/notes/${newNote.noteId}`);
          // TODO: when that nav above occured, the page title was not updated when I changed and saved the note title
        }
      } else {
        await saveNote({
          content,
          title,
          category,
          from,
        });
      }
      if (!onSaveStayOnNote) {
        nav("/");
      } else {
        setIsLoading(false);
        setOriginalTitle(title);
        setOriginalCategory(category);
        setOriginalContent(content);
        setOriginalFrom(from);
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function deleteNote() {
    return API.del("notes", `/notes/${id}`);
  }

  async function handleDelete(event) {
    event.preventDefault();

    const confirmed = window.confirm("Are you sure you want to delete this note?");

    if (!confirmed) {
      return;
    }

    setIsDeleting(true);

    try {
      await deleteNote();
      nav("/");
    } catch (e) {
      onError(e);
      setIsDeleting(false);
    }
  }

  function splitSentences(text) {
    text = text.replace(new RegExp("\\.[ ]{1,2}<i", "g"), "\r<i");
    text = text.replace(new RegExp("\\.[ ]{1,2}<a", "g"), "\r<a");
    text = text.replace(new RegExp("&nbsp;", "g"), " ");
    text = text.replace(new RegExp("</li><li>", "g"), "\r");
    text = text.replace(new RegExp("<ul><li>", "g"), "\r");
    text = text.replace(new RegExp("<ol><li>", "g"), "\r");
    text = text.replace(new RegExp("<br\\s*\\/?>", "g"), "\r");
    // Sentence split logic from https://stackoverflow.com/questions/34784676/split-string-into-sentences-ignoring-abbreviations-for-splitting
    var regex = /\b(\w\.\w\.|[A-Z][a-z]{1,2}\.)|([.?!])\s+(?=[A-Za-z])/g;
    return text
      .replace(regex, function (m, g1, g2) {
        return g1 ? g1 : g2 + "\r";
      })
      .replaceAll("\n", "\r")
      .split("\r")
      .map((element) => {
        element = element.trim();
        return element;
      })
      .filter((el) => el !== "");
  }

  function readingViewClick(event) {
    const elapsedTime = new Date().getTime() - lastSwipeEvent;

    if (elapsedTime < 20) {
      return;
    }

    const readingWrapperDiv = event.target.closest("div.readingWrapper");
    const activeParagraph = parseInt(readingWrapperDiv.getAttribute("data-key"));
    setActiveReadingParagraphNumber(activeParagraph);

    // Split text into sentences.
    const theSentences = splitSentences(readingWrapperDiv.innerText);

    let htmlForSplitting = readingWrapperDiv.innerHTML;
    if (htmlForSplitting.startsWith("<p>")) {
      htmlForSplitting = htmlForSplitting.substr(3);
    }
    if (htmlForSplitting.endsWith("</p>")) {
      htmlForSplitting = htmlForSplitting.slice(0, -4);
    }
    if (htmlForSplitting.startsWith("<h2>") || htmlForSplitting.startsWith("<h3>") || htmlForSplitting.startsWith("<h4>")) {
      htmlForSplitting = htmlForSplitting.substr(4);
    }
    if (htmlForSplitting.endsWith("</h2>") || htmlForSplitting.endsWith("</h3>") || htmlForSplitting.endsWith("</h4>")) {
      htmlForSplitting = htmlForSplitting.slice(0, -5);
    }
    if (htmlForSplitting.startsWith("<ul><li>") || htmlForSplitting.startsWith("<ol><li>")) {
      htmlForSplitting = htmlForSplitting.substr(8);
    }
    if (htmlForSplitting.endsWith("</li></ul>") || htmlForSplitting.endsWith("</li></ol>")) {
      htmlForSplitting = htmlForSplitting.slice(0, -10);
    }
    if (htmlForSplitting.includes('<span class="activeSentence">')) {
      htmlForSplitting = htmlForSplitting.replace('<span class="activeSentence">', "");
      htmlForSplitting = htmlForSplitting.replace("</span>", "");
    }

    const theHtmlSentences = splitSentences(htmlForSplitting);

    if (theSentences.length !== theHtmlSentences.length) {
      alert("sentence split issue!");

      onError(
        Error(
          "sentence split issue!",
          "text: " + readingWrapperDiv.innerText,
          theSentences.length,
          theSentences,
          "html: " + readingWrapperDiv.innerHTML,
          theHtmlSentences.length,
          theHtmlSentences
        )
      );

      console.log("sentence split issue!");
      console.log("text:", readingWrapperDiv.innerText);
      console.log(theSentences);
      console.log("html:", readingWrapperDiv.innerHTML);
      console.log(theHtmlSentences);
    }

    // Start reading at the beginnign of the paragraph
    const activeSentence = 0;

    speakIt(theSentences, theHtmlSentences, activeSentence, readingWrapperDiv);
  }

  function speakIt(sentences, htmlSentences, sentenceNumber, el) {
    const emojisRegex = /([\uE000-\uF8FF]|[\u2020-\u26FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|\uD83E[\uDD10-\uDDFF])/g;
    // |[\u2011-\u26FF]

    setCurrentParagraphSentences(sentences);
    setCurrentParagraphHtmlSentences(htmlSentences);
    setActiveReadingSentenceNumber(sentenceNumber);
    setCurrentReadingDiv(el);

    const sentenceToRead = sentences[sentenceNumber];

    if (sentenceToRead && speakText) {
      Predictions.convert({
        textToSpeech: {
          source: {
            text: sentenceToRead.replace(emojisRegex, ""),
          },
        },
      })
        .then((result) => {
          let htmlSentence = htmlSentences[sentenceNumber];
          if (htmlSentence.startsWith("<blockquote><p>")) {
            htmlSentence = htmlSentence.replace("<blockquote><p>", "");
          }
          setActiveReadingSentenceHTML(htmlSentence);

          setShowPlayButton(true);
          audio.src = result.speech.url;

          audio.onended = function () {
            if (sentenceNumber + 1 < sentences.length) {
              speakIt(sentences, htmlSentences, sentenceNumber + 1, el);
            } else {
              if (autoAdvanceWhenSpeakingText && el.nextSibling) {
                el.nextSibling.click();
              }
            }
          };

          audio.play();
        })
        .catch((err) => alert(JSON.stringify(err, null, 2)));
    } else {
      if (!speakText) {
        console.log("not playing because speak text is turned off");
      }
    }
  }

  // from https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript
  function escapeRegex(string) {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  }

  function identifySwipedTextForDeletion(event) {
    if (event.target.localName === "ul" || event.target.localName === "ol") {
      return;
    }
    let retVal = "";

    if (event.target.localName === "li") {
      retVal = event.target.outerHTML;
    } else if (event.target.localName === "span" && event.target.className === "activeSentence" && event.target.closest("li")) {
      retVal = event.target.closest("li").outerHTML;
    } else {
      retVal = event.target.closest("div.readingWrapper").innerHTML;
    }

    if (retVal.includes('<span class="activeSentence">')) {
      retVal = retVal.replace('<span class="activeSentence">', "");
      retVal = retVal.replace("</span>", "");
    }
    return retVal;
  }

  // original from: http://www.javascriptkit.com/javatutors/touchevents2.shtml
  function elementTouchStart(event) {
    const contentSwiped = identifySwipedTextForDeletion(event);
    const touchobj = event.changedTouches[0];
    if (contentSwiped) {
      setSwipeStartInfo({
        contentSwiped: contentSwiped,
        startX: touchobj.pageX,
        startY: touchobj.pageY,
        startTime: new Date().getTime(),
      });
    }
  }

  // original from: http://www.javascriptkit.com/javatutors/touchevents2.shtml
  function elementTouchEnd(event) {
    // parameters to determine a swipe
    const threshold = 50; //required min distance traveled to be considered swipe
    const restraint = 50; // maximum distance allowed at the same time in perpendicular direction
    const allowedTime = 300; // maximum time allowed to travel that distance

    if (swipeStartInfo) {
      // determine if a sufficient swipe occurred
      const touchobj = event.changedTouches[0];
      const distX = touchobj.pageX - swipeStartInfo.startX; // get horizontal dist traveled by finger while in contact with surface
      const distY = touchobj.pageY - swipeStartInfo.startY; // get vertical dist traveled by finger while in contact with surface
      const elapsedTime = new Date().getTime() - swipeStartInfo.startTime; // get time elapsed
      let swipedir = "";

      if (elapsedTime <= allowedTime) {
        // first condition for a swipe met
        if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint) {
          // 2nd condition for horizontal swipe met
          swipedir = distX < 0 ? "left" : "right"; // if dist traveled is negative, it indicates left swipe
        } else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint) {
          // 2nd condition for vertical swipe met
          swipedir = distY < 0 ? "up" : "down"; // if dist traveled is negative, it indicates up swipe
        }
      }

      // then if that swipe was left or right
      if (["left", "right"].includes(swipedir)) {
        const contentSwiped = identifySwipedTextForDeletion(event);
        if (contentSwiped && swipeStartInfo.contentSwiped === contentSwiped) {
          setActiveReadingParagraphNumber(-1);
          setActiveReadingSentenceHTML("");
          if (!audio.paused) {
            audio.currentTime = 0;
            audio.pause();
          }
          if (window.confirm(`Delete: ${contentSwiped}`)) {
            const newContent = content.replace(contentSwiped, "");
            setContent(newContent);
          }
        }
        setLastSwipeEvent(new Date().getTime());
      }
      setSwipeStartInfo({});
    }
  }

  function renderReadingView() {
    let retVal = [];

    if (content) {
      // console.log('\n--------------------\n')

      let contentForReadingView = content;

      if (activeReadingSentenceHTML) {
        const contentBeforeReplace = contentForReadingView;

        contentForReadingView = contentForReadingView.replace(
          new RegExp(escapeRegex(activeReadingSentenceHTML), "g"),
          '<span class="activeSentence">' + activeReadingSentenceHTML + "</span>"
        );

        if (contentForReadingView === contentBeforeReplace) {
          onError(["did not find a match for activeReadingSentenceHTML: " + activeReadingSentenceHTML, "in:", contentForReadingView], false);

          console.log("did not find a match for activeReadingSentenceHTML:", activeReadingSentenceHTML);
          console.log("in:", contentForReadingView);
        }
      }
      const parsed = parse(contentForReadingView);

      if (parsed && Array.isArray(parsed)) {
        parsed.forEach((x, i) => {
          if (x.props) {
            if (x.props.children && typeof x.props.children === "object" && x.props.children.type === "img") {
              // TODO: still need to do something about images to point out that there's an image to look at
              // console.log('TODO there\'s an image')
              // console.log(x)
              // console.log()
            } else if (["p", "figure", "blockquote", "ol", "ul", "h2", "h3", "h4", "span"].includes(x.type)) {
              // console.log(x)
              // x.onClick = 'readingViewClick'
              retVal.push(
                <div
                  key={i}
                  data-key={i}
                  className={i === activeReadingParagraphNumber ? "readingWrapper activeParagraph" : "readingWrapper"}
                  ref={i === activeReadingParagraphNumber ? focusRef : null}
                  onClick={readingViewClick}
                  onTouchStart={elementTouchStart}
                  onTouchEnd={elementTouchEnd}
                  onMouseDown={(event) => elementTouchStart({ changedTouches: [event.nativeEvent], target: event.target })}
                  onMouseUp={(event) => elementTouchEnd({ changedTouches: [event.nativeEvent], target: event.target })}
                >
                  {x}
                </div>
              );
            } else {
              if (x.props.children !== null) {
                onError("This is not being addressed: " + x.type + " " + x.props.children);
                console.log("This is not being addressed:", x.type, x.props.children);
              }
            }
          }
        });
      } else {
        // not an array, single element
        retVal.push(
          <div
            key="0"
            data-key="0"
            className={0 === activeReadingParagraphNumber ? "readingWrapper activeParagraph" : "readingWrapper"}
            ref={0 === activeReadingParagraphNumber ? focusRef : null}
            onClick={readingViewClick}
            onTouchStart={elementTouchStart}
            onTouchEnd={elementTouchEnd}
            onMouseDown={(event) => elementTouchStart({ changedTouches: [event.nativeEvent], target: event.target })}
            onMouseUp={(event) => elementTouchEnd({ changedTouches: [event.nativeEvent], target: event.target })}
          >
            {parsed}
          </div>
        );
      }
    }

    return retVal;
  }

  function renderCreateSaveButton() {
    return originalTitle !== title || originalCategory !== category || originalContent !== content || originalFrom !== from ? (
      <LoaderButton
        block="true"
        size="lg"
        type="submit"
        variant="primary"
        isLoading={isLoading}
        disabled={!validateForm()}
      >
        {id === "new" ? "Create" : "Save"}
      </LoaderButton>
    ) : undefined;
  }

  const blankTagsRegex = new RegExp("<\\w{1,2}>(\\s|&nbsp; ?|\u200B)*<\\/\\w{1,2}>", "g");
  const brTagsRegex = new RegExp("<br\\s*\\/?>", "g");
  const sponsoredRegex = new RegExp("<p>[^<]+?(SPONSORED|Sponsored)\\)?<\\/p>", "g");

  const pegboardSectionsIDoNotCareAbout = [
    // pegboardBeginningRegex
    new RegExp("<p>New Services<\\/p>"),
    new RegExp("<p>The following services were added to the Cloud Pegboard database within the last week.\\s*<\\/p>"),
    new RegExp("<p>Go to Cloud Pegboard if you would like to add these to your watch list.\\s*<\\/p>"),
    new RegExp("<p>--- No new services ---<\\/p>"),
    new RegExp("<p>Changed services in your watch list<\\/p>"),
    new RegExp("<p>Following are changes to services in your watch list in the last day.<\\/p>"),
    new RegExp("<p>Your filter options \\(see your profile to change\\):<\\/p>"),
    new RegExp("<p>\\s*Region changes are filtered out<\\/p>"),
    new RegExp("<p>\\s*Compliance changes are filtered out<\\/p>"),
    new RegExp("<p>\\s*Wishlist tweets are filtered out<\\/p>"),
    // pegboardEndingRegex
    new RegExp("<p>Other Cloud Pegboard News<\\/p>"),
    new RegExp("<p>Personalize this email<\\/p>"),
    new RegExp("<p>You can focus this email update to just the services that you care about. See Creating lists to easily set this up.<\\/p>"),
    new RegExp("<p>You can also control what information is included in each update. Configure information-type suppressions in your profile.<\\/p>"),
    new RegExp("<p>Share Cloud Pegboard<\\/p>"),
    new RegExp(
      "<p>If you find these updates and the Cloud Pegboard portal helpful, please share www.cloudpegboard.com with your entire team or favorite social media channels.<\\/p>"
    ),
    new RegExp("<p>Feedback<\\/p>"),
    new RegExp("<p>Please send feedback so that we can make this a fantastically useful feature for you.<\\/p>"),
    new RegExp("<p>FEEDBACK<\\/p>"),
    new RegExp("<p>This email was sent to you because you requested personalized updates.<\\/p>"),
    new RegExp("<p>© 2020, Cloud Pegboard. All rights reserved.<\\/p>"),
    new RegExp("<p>365 Boston Post Road #132, Sudbury, MA 01776<\\/p>"),
    new RegExp("<p>Unsubscribe • Privacy policy • Contact us<\\/p>"),
    // pegboardSections
    new RegExp("<p>(AWS|Amazon) [a-zA-Z0-9\\s\\(\\)-]+<\\/p><p>Announcements (&amp;|&) Blogs:<\\/p>", "g"),
    new RegExp("<p>(AWS|Amazon) [a-zA-Z0-9\\s\\(\\)-]+<\\/p><p>New<\\/p>", "g"),
    new RegExp("<p>New<\\/p>", "g"),
  ];
  const pegboardParagraphsIDoNotCareAbout = [
    new RegExp("Amazon Connect", "g"),
    new RegExp("Chime", "g"),
    new RegExp("VMware", "g"),
    new RegExp("available in", "g"),
    new RegExp("SageMaker", "g"),
    new RegExp("IoT", "g"),
    new RegExp("(T|t)erraform"), //, "g"),
    new RegExp("AWS Partner Network"), //, "g"),
  ];

  // TODO: create coreySectionsIDoNotCareAbout
  // <p>... and that’s what happened Last Week in AWS. If you’ve enjoyed reading this, tell everyone you know to subscribe at lastweekinaws.com.</p>
  // <p>As always, if you’ve seen a blog post, a tool, or anything else AWS related that you think the rest of the community should hear about, send them my way. You can either hit reply or join the #lastweekinaws channel on the LWIA Slack team.</p>
  // <p>Refer people to Last Week in AWS</p>
  // <p>Share Last Week in AWS with your friends, colleagues, neighbors, and sworn enemies! Trick them into subscribing! Earn a variety of rewards when they sign up! Here’s your personal referral link:</p>
  // <p>https://sparklp.co/ee2a8cef</p>
  // <p>You currently have 0 referrals.</p>
  // <p>Get Some Swag</p>
  // <p>That’s right folks, we’ve got awesome swag. All of our swag features everyone’s favorite mascot, Billie The Platypus. Get yours here.</p>
  // <p>Corey Quinn</p>
  // <p>I'm Corey Quinn</p>
  // <p>I help companies improve their horrifying AWS bills by making them smaller and less horrifying. I also host two podcasts--check them out at lastweekinaws.com.</p>
  // <p>To make sure you keep getting these emails, please add corey@lastweekinaws.com to your address book or otherwise mark me as a permitted sender.</p>
  // <p>Want to change the emails you're getting? Manage which ridiculous emails you're getting from me.</p>
  // <p>Or if you'd rather never get another email from me ever again: opt out of everything forever.</p>
  // <p>The Duckbill Group</p>
  // <p>548 Market St, PMB 88528, San Francisco, CA 94104</p>
  // <p>Twitter Linked In releases</p>

  // original from https://stackoverflow.com/questions/10152650/javascript-match-string-against-the-array-of-regular-expressions
  function matchInArray(string, regexpressions) {
    const len = regexpressions.length;
    let i = 0;

    for (; i < len; i++) {
      if (regexpressions[i].test(string)) {
        return true;
      }
    }
    return false;
  }

  function getMatches(string, regexpressions) {
    let matches = [];

    // split paragraphs
    if (string.startsWith("<p>")) {
      string = string.substr(3);
    }
    if (string.endsWith("</p>")) {
      string = string.slice(0, -4);
    }
    let paragraphs = string.split("</p><p>");

    // console.log('paragraphs:', paragraphs)

    paragraphs.forEach((paragraph) => {
      // let increaseLogging = false

      // if (paragraph.includes('erraform')) {
      //   increaseLogging = true
      // }

      // if (increaseLogging)
      //   console.log('\nChecking the paragraph:', paragraph)

      // const matchesCountBefore = matches.length;

      regexpressions.forEach((regex) => {
        const result = regex.test(paragraph);

        // if (regex.toString().includes('erraform') && increaseLogging) {
        //   console.log({
        //     'regex': regex,
        //     'paragraph': paragraph,
        //     'result': result
        //   });
        // }

        if (result) {
          // console.log('Adding the following paragraph to matches:', paragraph)
          matches.push(paragraph);
        }
      });

      // if (matches.length === matchesCountBefore) {
      //   if (increaseLogging)
      //     console.log('Not a match:', paragraph)
      // }
    });

    // console.log('returning the matches:', matches)
    return matches;
  }

  const ckeditorChangeHandler = (event, editor) => {
    setActiveReadingSentenceHTML("");
    setActiveReadingParagraphNumber(-1);
    setContent(editor.getData());
  };

  // idea from https://dmitripavlutin.com/react-throttle-debounce/
  const debouncedCKEditorChangeHandler = useMemo(
    () => debounce(ckeditorChangeHandler, 5000, { leading: true, maxWait: 10000, trailing: true }), // 5 seconds, 10 seconds
    []
  );

  const ckeditorBlurHandler = (event, editor) => {
    setContent(editor.getData());
    setWysiwygEditorHeight(editor.ui.view.element.clientHeight);
  };

  const wordCount = useCallback(() => {
    if (content)
      return content
        .replace(new RegExp("&nbsp;", "g"), "")
        .replace(new RegExp("<p>\\s*</p>", "g"), "")
        .replace(new RegExp("a\\s+href", "g"), "ahref")
        .replace(new RegExp("<br\\s*\\/?>", "g"), " ")
        .replace(/<\/p>/g, " ")
        .trim()
        .split(/\s+/).length;
    else return 0;
  }, [content]);

  const readFromFirstParagraph = useCallback(() => {
    const paragraph = document.getElementsByClassName("readingWrapper")[0];

    if (paragraph) {
      if (window.confirm(wordCount() + " words\n\n" + paragraph.innerText)) {
        const updatedContent = content.replace(paragraph.innerHTML, "");
        setContent(updatedContent);
        setReadAnotherFromFirstParagraph(true);
      } else {
        setReadAnotherFromFirstParagraph(false);
      }
    }
  }, [wordCount, content]);

  useEffect(() => {
    if (readAnotherFromFirstParagraph) {
      // I do not like this, I wish I had another way to get the screen behind the confirm window to update between consecutive confirm windows
      setTimeout(() => readFromFirstParagraph(), 1);
    }
  }, [readAnotherFromFirstParagraph, readFromFirstParagraph]);

  function hasDuplicateParagraphs(localContent) {
    if (localContent.startsWith("<p>")) {
      localContent = localContent.substr(3);
    }
    if (localContent.endsWith("</p>")) {
      localContent = localContent.slice(0, -4);
    }

    let paragraphs = localContent.split("</p><p>");

    return !(paragraphs.length === [...new Set(paragraphs)].length);
  }

  function withoutDuplicateParagraphs(localContent) {
    if (localContent.startsWith("<p>")) {
      localContent = localContent.substr(3);
    }
    if (localContent.endsWith("</p>")) {
      localContent = localContent.slice(0, -4);
    }

    let paragraphs = localContent.split("</p><p>");

    return [...new Set(paragraphs)];
  }

  return (
    <div className="Note">
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="from">
          <Form.Label>From</Form.Label>
          <Form.Control
            value={from}
            onChange={(e) => setFrom(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="title">
          <Form.Label>Title</Form.Label>
          <Form.Control
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="category">
          <Form.Label>Category</Form.Label>
          <Form.Control
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </Form.Group>
        {renderCreateSaveButton()}
        <Form.Group controlId="content">
          <Form.Label>Content ({wordCount()} words)</Form.Label>
          <Tabs>
            <TabList>
              <Tab
                onClick={() => {
                  flushDebounce();
                  setReadingMode(false);
                }}
              >
                WYSIWYG
              </Tab>
              <Tab
                onClick={() => {
                  flushDebounce();
                  setReadingMode(false);
                }}
              >
                HTML
              </Tab>
              <Tab
                onClick={() => {
                  flushDebounce();
                  setReadingMode(true);
                }}
              >
                Reading
              </Tab>
              <Tab
                onClick={() => {
                  flushDebounce();
                  setReadingMode(false);
                }}
              >
                Settings
              </Tab>
            </TabList>

            {/* WYSIWYG */}
            <TabPanel>
              {blankTagsRegex.test(content) ? (
                <LoaderButton
                  block="true"
                  size="lg"
                  variant="secondary"
                  onClick={() => {
                    const updatedContent = content.replace(blankTagsRegex, "");
                    setContent(updatedContent);
                  }}
                >
                  Remove Blank Tags
                </LoaderButton>
              ) : undefined}
              {brTagsRegex.test(content) ? (
                <LoaderButton
                  block="true"
                  size="lg"
                  variant="secondary"
                  onClick={() => {
                    const updatedContent = content.replace(brTagsRegex, "");
                    setContent(updatedContent);
                  }}
                >
                  Remove BR Tags
                </LoaderButton>
              ) : undefined}
              {sponsoredRegex.test(content) ? (
                <LoaderButton
                  block="true"
                  size="lg"
                  variant="secondary"
                  onClick={() => {
                    const updatedContent = content.replace(sponsoredRegex, "");
                    setContent(updatedContent);
                  }}
                >
                  Remove SPONSORED
                </LoaderButton>
              ) : undefined}
              {hasDuplicateParagraphs(content) ? (
                <LoaderButton
                  block="true"
                  size="lg"
                  variant="secondary"
                  onClick={() => {
                    const updatedContent = "<p>" + withoutDuplicateParagraphs(content).join("</p><p>") + "</p>";
                    setContent(updatedContent);
                  }}
                >
                  Remove Duplicate Paragraphs
                </LoaderButton>
              ) : undefined}
              {title.toLowerCase().includes("pegboard") &&
              (matchInArray(content, pegboardSectionsIDoNotCareAbout) || matchInArray(content, pegboardParagraphsIDoNotCareAbout)) ? (
                <LoaderButton
                  block="true"
                  size="lg"
                  variant="secondary"
                  onClick={() => {
                    let localWorkingContent = content;

                    if (matchInArray(localWorkingContent, pegboardSectionsIDoNotCareAbout)) {
                      // console.log('removing sections I dont care about')
                      pegboardSectionsIDoNotCareAbout.forEach((sectionToRemove) => {
                        localWorkingContent = localWorkingContent.replace(sectionToRemove, "");
                      });
                    }

                    // remove duplicate paragraphs
                    if (hasDuplicateParagraphs(localWorkingContent)) {
                      // console.log('removing duplicate paragraphs')
                      localWorkingContent = "<p>" + withoutDuplicateParagraphs(localWorkingContent).join("</p><p>") + "</p>";
                    }

                    getMatches(localWorkingContent, pegboardParagraphsIDoNotCareAbout).forEach((paragraphToRemove) => {
                      // console.log('Removing the paragraph: ' + paragraphToRemove);
                      localWorkingContent = localWorkingContent.replace(paragraphToRemove, "");
                    });

                    // remove blank tags
                    if (blankTagsRegex.test(localWorkingContent)) {
                      // console.log('removing blank tags')
                      localWorkingContent = localWorkingContent.replace(blankTagsRegex, "");
                    }

                    setContent(localWorkingContent);
                  }}
                >
                  Remove Cloud Pegboard paragraphs I do not care about
                </LoaderButton>
              ) : undefined}
              <CKEditor
                editor={ClassicEditor}
                data={content}
                onReady={(editor) => setWysiwygEditorHeight(editor.ui.view.element.clientHeight)}
                onChange={debouncedCKEditorChangeHandler}
                onBlur={ckeditorBlurHandler}
              />
            </TabPanel>

            {/* HTML */}
            <TabPanel>
              <Form.Control
                as="textarea"
                style={{ height: wysiwygEditorHeight }}
                value={content}
                onBlur={(e) => {
                  setActiveReadingParagraphNumber(-1);
                  if (e.target.value.includes("<p>")) {
                    setContent(e.target.value);
                  } else {
                    setContent("<p>" + e.target.value.replace(/(?:\r\n|\r|\n)+/g, "</p><p>") + "</p>");
                  }
                }}
                onChange={(e) => {
                  setContent(e.target.value);
                }}
              />
            </TabPanel>

            {/* Reading */}
            <TabPanel>
              <LoaderButton
                block="true"
                size="lg"
                variant="secondary"
                onClick={readFromFirstParagraph}
              >
                Read ¶x¶
              </LoaderButton>
              <div
                className="ReadingView"
                tabIndex="-1"
              >
                {renderReadingView()}
              </div>
            </TabPanel>

            {/* Settings */}
            <TabPanel>
              <label className="switch">
                <span className="label">Speak Text:</span>
                <input
                  name="autoAdvance"
                  type="checkbox"
                  checked={speakText}
                  onChange={() => {
                    if (speakText) {
                      audio.pause();
                      setActiveReadingSentenceHTML("");
                    }
                    setSpeakText(!speakText);
                  }}
                />
                <span className="slider round"></span>
              </label>
              <label className="switch">
                <span className="label">Auto Advance when Speaking Text:</span>
                <input
                  name="autoAdvance"
                  type="checkbox"
                  checked={autoAdvanceWhenSpeakingText}
                  onChange={() => {
                    if (!audio.pause) {
                      audio.pause();
                      setActiveReadingParagraphNumber(-1);
                    }
                    setAutoAdvanceWhenSpeakingText(!autoAdvanceWhenSpeakingText);
                  }}
                />
                <span className="slider round"></span>
              </label>
              <label className="switch">
                <span className="label">On save stay on note:</span>
                <input
                  name="onSaveStayOnNote"
                  type="checkbox"
                  checked={onSaveStayOnNote}
                  onChange={() => setOnSaveStayOnNote(!onSaveStayOnNote)}
                />
                <span className="slider round"></span>
              </label>
            </TabPanel>
          </Tabs>
        </Form.Group>
        {renderCreateSaveButton()}
        <LoaderButton
          block="true"
          size="lg"
          variant="secondary"
          onClick={() => nav("/")}
        >
          Cancel
        </LoaderButton>
        {note && (
          <LoaderButton
            block="true"
            size="lg"
            variant="danger"
            onClick={handleDelete}
            isLoading={isDeleting}
          >
            Delete
          </LoaderButton>
        )}
      </Form>
      {showPlayButton ? (
        <>
          <br />
          <br />
          <div
            onClick={playPause}
            id="playPause"
          >
            Play/Pause
          </div>
        </>
      ) : null}
    </div>
  );
}
