import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../components/LoaderButton";
import "./Settings.css";
import "./slider.css";
import { useAppContext } from "../lib/contextLib";

export default function Settings() {
  const { speakText, setSpeakText } = useAppContext();
  const { onSaveStayOnNote, setOnSaveStayOnNote } = useAppContext();
  const { autoAdvanceWhenSpeakingText, setAutoAdvanceWhenSpeakingText } = useAppContext();

  function toggleSpeakText() {
    setSpeakText(!speakText);
  }

  function toggleOnSaveStayOnNote() {
    setOnSaveStayOnNote(!onSaveStayOnNote);
  }

  function toggleAutoAdvanceWhenSpeakingText() {
    setAutoAdvanceWhenSpeakingText(!autoAdvanceWhenSpeakingText);
  }

  return (
    <div className="Settings">
      <label className="switch">
        <span className="label">Speak Text:</span>
        <input name="autoAdvance" type="checkbox" checked={speakText} onChange={toggleSpeakText} />
        <span className="slider round"></span>
      </label>
      <label className="switch">
        <span className="label">Auto Advance when Speaking Text:</span>
        <input
          name="autoAdvance"
          type="checkbox"
          checked={autoAdvanceWhenSpeakingText}
          onChange={toggleAutoAdvanceWhenSpeakingText}
        />
        <span className="slider round"></span>
      </label>
      <label className="switch">
        <span className="label">On save stay on note:</span>
        <input
          name="onSaveStayOnNote"
          type="checkbox"
          checked={onSaveStayOnNote}
          onChange={toggleOnSaveStayOnNote}
        />
        <span className="slider round"></span>
      </label>

      <LinkContainer to="/settings/password">
        <LoaderButton block size="lg">
          Change Password
        </LoaderButton>
      </LinkContainer>
    </div>
  );
}
