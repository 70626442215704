import React, { useState } from "react";
import { Auth } from "aws-amplify";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../lib/contextLib";
import { useFormFields } from "../lib/hooksLib";
import { onError } from "../lib/errorLib";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";

export default function Login() {
  const nav = useNavigate();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [mustChangePassword, setMustChangePassword] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [isChanging, setIsChanging] = useState(false);

  function validateLoginForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  function validateChangePasswordForm() {
    return (
      fields.password.length > 0 &&
      fields.newPassword.length > 0 &&
      fields.newPassword === fields.confirmNewPassword
    );
  }

  async function handleSubmitLoginForm(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const user = await Auth.signIn(fields.email, fields.password);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        alert("Your password must be changed prior to logging on.");
        setMustChangePassword(true);
      } else {
        userHasAuthenticated(true);
      }
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  async function handleSubmitChangePasswordForm(event) {
    event.preventDefault();

    setIsChanging(true);

    try {
      const user = await Auth.signIn(fields.email, fields.password);
      await Auth.completeNewPassword(user, fields.newPassword);
      await Auth.signIn(fields.email, fields.newPassword);

      userHasAuthenticated(true);

      nav("/");
    } catch (e) {
      onError(e);
      setIsChanging(false);
    }
  }

  function renderMustChangePasswordForm() {
    return (
      <div className="ChangePassword">
        <Form onSubmit={handleSubmitChangePasswordForm}>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
              readOnly
            />
          </Form.Group>
          <hr />
          <Form.Group size="lg" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control type="password" value={fields.newPassword} onChange={handleFieldChange} />
          </Form.Group>
          <Form.Group size="lg" controlId="confirmNewPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={fields.confirmNewPassword}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <LoaderButton
            block="true"
            size="lg"
            type="submit"
            isLoading={isChanging}
            disabled={!validateChangePasswordForm()}
          >
            Change Password
          </LoaderButton>
        </Form>
      </div>
    );
  }

  function renderLoginForm() {
    return (
      <div className="Login">
        <Form onSubmit={handleSubmitLoginForm}>
          <Form.Group size="lg" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" value={fields.password} onChange={handleFieldChange} />
          </Form.Group>
          <Link to="/login/reset">Forgot password?</Link>
          <LoaderButton
            block="true"
            size="lg"
            type="submit"
            isLoading={isLoading}
            disabled={!validateLoginForm()}
          >
            Login
          </LoaderButton>
        </Form>
      </div>
    );
  }

  return <>{!mustChangePassword ? renderLoginForm() : renderMustChangePasswordForm()}</>;
}
