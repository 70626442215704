import React from "react";
import { createRoot } from "react-dom/client";
import { Amplify } from "aws-amplify";
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { initSentry } from "./lib/errorLib";
import config from "./config";
import "bootstrap/dist/css/bootstrap.min.css";

const container = document.getElementById("root");
const root = createRoot(container);

initSentry();

if (Amplify.Predictions._identifyPluggables.length === 0)
  Amplify.addPluggable(new AmazonAIPredictionsProvider());

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "notes",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
  predictions: {
    convert: {
      speechGenerator: {
        region: config.polly.REGION,
        proxy: false,
        defaults: {
          VoiceId: config.polly.VOICE_ID,
          LanguageCode: config.polly.LANGUAGE_CODE,
        },
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
