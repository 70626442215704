import React, { useState, useEffect } from "react";
import { BsPencilSquare } from "react-icons/bs";
import ListGroup from "react-bootstrap/ListGroup";
import { LinkContainer } from "react-router-bootstrap";
import { useAppContext } from "../lib/contextLib";
import { onError } from "../lib/errorLib";
import { API } from "aws-amplify";
import "./Home.css";
import Form from "react-bootstrap/Form";
import LoaderButton from "../components/LoaderButton";

export default function Home() {
  const [notes, setNotes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const { filter, setFilter } = useAppContext();
  const { search, setSearch } = useAppContext();
  const { setTotalNotes } = useAppContext();
  const [categories, setCategories] = useState({});
  const [newItem, setNewItem] = useState(false);

  useEffect(() => {
    document.title = "Notes";

    async function onLoad() {
      if (!isAuthenticated) {
        return;
      }

      try {
        const notes = await loadNotes();
        setTotalNotes(notes.length);

        // original from https://www.tutorialspoint.com/counting-unique-elements-in-an-array-in-javascript
        const categoryCounts = {};
        for (let i = 0; i < notes.length; i++) {
          categoryCounts[notes[i].category] = 1 + (categoryCounts[notes[i].category] || 0);
        }
        setCategories(categoryCounts);

        setNotes(notes);
      } catch (e) {
        onError(e);
      }

      setIsLoading(false);
      setNewItem(false);
    }

    onLoad();
  }, [isAuthenticated, setTotalNotes, newItem]);

  function loadNotes() {
    return API.get("notes", "/notes");
  }

  function wordCount(content) {
    if (content)
      return content
        .replace(new RegExp("&nbsp;", "g"), "")
        .replace(new RegExp("<p>\\s*</p>", "g"), "")
        .replace(new RegExp("a\\s+href", "g"), "ahref")
        .replace(new RegExp("<br\\s*\\/?>", "g"), " ")
        .replace(/<\/p>/g, " ")
        .trim()
        .split(/\s+/).length;
    else return 0;
  }

  function pegboardNotes(notes) {
    return notes.filter((el) => el.title.toLowerCase().includes("pegboard"));
  }

  async function createCombinedPegboardNote(notes) {
    const pBN = pegboardNotes(notes);
    await API.post("notes", "/notes", {
      body: {
        content: pBN.map(({ content }) => content).join(""),
        title: "Combined pegboard",
        category: "ToReadCloudPegboard",
      },
    });

    const promises = [];
    for (const item of pBN) {
      promises.push(API.del("notes", `/notes/${item.noteId}`));
    }
    await Promise.all(promises);

    setNewItem(true);
  }

  function renderNotesList(notes) {
    let totalWords = 0;

    return (
      <>
        <div>
          <select
            style={{ width: "50%", height: "38px", float: "left" }}
            name="categories"
            id="categories"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option key="" value="">
              Select to filter
            </option>
            {Object.entries(categories).map((x) => (
              <option key={x[0]} value={x[0]}>
                {x[0]} ({x[1]})
              </option>
            ))}
          </select>
          <Form.Control
            placeholder="Search"
            style={{ width: "50%", height: "38px", float: "left" }}
            name="search"
            id="search"
            value={search}
            onChange={(e) => setSearch(e.target.value.toLowerCase())}
          />
        </div>

        <LinkContainer to="/notes/new">
          <ListGroup.Item action className="py-3 text-nowrap text-truncate">
            <BsPencilSquare size={17} />
            <span className="ml-2 font-weight-bold">Create a new note</span>
          </ListGroup.Item>
        </LinkContainer>
        {pegboardNotes(notes).length > 1 ? (
          <LoaderButton
            block="true"
            size="lg"
            variant="secondary"
            onClick={() => createCombinedPegboardNote(notes)}
          >
            Combine pegboard notes
          </LoaderButton>
        ) : undefined}
        {notes
          .filter((el) => !filter || el.category === filter)
          .filter(
            (el) =>
              !search ||
              el.content.toLowerCase().includes(search) ||
              el.title.toLowerCase().includes(search)
          )
          .map(({ noteId, content, createdAt, title, category, modifiedAt }) => {
            const numWords = wordCount(content);
            totalWords += numWords;
            return (
              <LinkContainer key={noteId} to={`/notes/${noteId}`}>
                <ListGroup.Item action>
                  <strong>{title}</strong> , {category}
                  <br />
                  <span className="text-muted">
                    Modified: {new Date(modifiedAt).toLocaleString()}
                  </span>
                  <br />
                  <span className="text-muted">Words {numWords}</span>
                </ListGroup.Item>
              </LinkContainer>
            );
          })}
        <p>Total Words {totalWords}</p>
      </>
    );
  }

  function renderLander() {
    return (
      <div className="lander">
        <h1>Notes</h1>
        <p className="text-muted">A simple note taking app</p>
      </div>
    );
  }

  function renderNotes() {
    return (
      <div className="notes">
        <ListGroup>{!isLoading && renderNotesList(notes)}</ListGroup>
      </div>
    );
  }

  return <div className="Home">{isAuthenticated ? renderNotes() : renderLander()}</div>;
}
